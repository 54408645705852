.rfu-icon-button {
  cursor: pointer;
  position: relative;
  padding: 4px;
  align-items: center;
  justify-content: center;

  svg {
    margin: 4px;
    position: relative;
    z-index: $z5;
    fill: #a0b2b8;
  }

  &:hover {
    svg {
      fill: color(#a0b2b8 shade(15%));
    }
  }
}
