.rfu-dropzone .rfu-dropzone__notifier {
  position: absolute;
  height: 100%;
  width: 100%;
  padding: 30px;
  z-index: $z9;
  display: none;
  border-radius: $borderRadius;
}

.rfu-dropzone--accept .rfu-dropzone__notifier {
  background: color($primary a(83%));
  display: block;
}

.rfu-dropzone--reject .rfu-dropzone__notifier {
  background: color($error a(83%));
  display: block;
}

.rfu-dropzone__inner {
  width: 100%;
  height: 100%;
  padding: 30px;
  border: 1px dashed $white;
  border-radius: $borderRadius;
  box-sizing: border-box;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: $white;
  font-weight: 800;
  font-size: 12px;
}

.rfu-dropzone--reject .rfu-dropzone__inner {
  display: none;
}
