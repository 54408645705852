.rfu-thumbnail-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border: 1px dashed color($black tint(75%));
  border-radius: $borderRadius;
  cursor: pointer;

  &:hover {
    background: color($black tint(95%));
  }
}
