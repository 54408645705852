.rfu-image-previewer {
  display: flex;
  flex-wrap: wrap;
  margin: 8px 0;

  &__image {
    width: 100px;
    height: 100px;
    position: relative;
    margin-right: 8px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    &--loaded {
      .rfu-thumbnail__overlay {
        background: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.4) 0%,
          rgba(0, 0, 0, 0) 100%
        );
      }
    }

    & .rfu-thumbnail__wrapper {
      position: absolute;
    }

    & .rfu-loading-indicator {
      position: absolute;
      z-index: $z9;
    }
  }

  &__retry {
    z-index: $z9;
  }
}
