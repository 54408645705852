.rfu-loading-indicator {
  margin: 0 auto;
  width: 70px;
  text-align: center;
}

.rfu-loading-indicator > div {
  width: 18px;
  height: 18px;
  background-color: color($black tint(80%));
  border-radius: 100%;
  display: inline-block;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.rfu-loading-indicator .bounce1 {
  animation-delay: -0.32s;
}

.rfu-loading-indicator .bounce2 {
  animation-delay: -0.16s;
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.rfu-loading-indicator__spinner {
  width: 20px;
  height: 20px;
  border: 2px solid #eee;
  border-top-color: $primary;
  border-radius: 50%;
  animation: spinner 0.6s linear infinite;
}
