.rfu-thumbnail__wrapper {
  width: 100px;
  height: 100px;
  border-radius: $borderRadius;
  overflow: hidden;
  position: relative;
}

.rfu-thumbnail__overlay {
  position: absolute;
  background-color: color($black a(40%));
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 5px;
}

.rfu-thumbnail__image {
  width: inherit;
  height: inherit;
  object-fit: cover;
}
