.rfu-image-upload-button {
  cursor: pointer;

  svg {
    fill: #a0b2b8;
  }

  &:hover {
    svg {
      fill: color(#a0b2b8 shade(15%));
    }
  }

  label {
    cursor: pointer;
  }

  .rfu-image-input {
    width: 0;
    height: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
}
