.rfu-file-previewer {
  border: 1px solid color($black a(10%));
  border-radius: 4px;
  overflow: hidden;
  margin: 8px 0;
  position: relative;

  ol {
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      position: relative;
      padding: 8px 16px;
      border-bottom: 1px solid color($black a(10%));

      &:last-child {
        border-color: transparent;
      }
    }
  }

  &__file {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: #fafafa;
    }

    a {
      flex: 1;
      margin: 0 8px;
      color: $fontColor;
      text-decoration: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    svg {
      min-width: 25px;
    }

    &--uploading {
      opacity: 0.4;
    }

    &--failed {
      a {
        display: flex;
        align-items: center;
        color: color($fontColor tint(39%));

        &::after {
          text-decoration: none;
        }
      }
    }
  }

  &__image {
    min-width: 25px;
    display: flex;
  }

  &__loading-indicator {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  &__close-button {
    position: relative;
    z-index: 10000;
  }

  &__failed {
    padding: 3px 6px;
    margin-left: 8px;
    color: $white;
    border-radius: $borderRadius;
    background: color($error tint(39%));
    font-size: 12px;
  }

  &__retry {
    text-decoration: none;
    padding: 3px 6px;
    margin-left: 8px;
    color: $white;
    border-radius: $borderRadius;
    background: color($primary tint(39%));
    font-size: 12px;
  }
}
